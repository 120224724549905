import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import type { DispatchWithoutAction, Reducer } from "react"
import { useEffect, useMemo, useReducer, useState } from "react"

import {
	useFetchAppointmentFiltersQuery,
	useFetchAppointmentsQuery,
	useFetchCoreQuery
} from "~/api/osteo-physio/client"
import type { APIError } from "~/api/osteo-physio/types/error"
import { isReduxDataAPIError } from "~/api/osteo-physio/types/error"
import { InflatedAppointment } from "~/classes/appointment"
import {
	useChosenDate,
	useChosenGenderIdentifier,
	useChosenLocationIdentifier,
	useChosenPractitionerIdentifier
} from "~/hooks/useAppointmentFilters"

/**
 * React hook to fetch the available appointments for today.
 * @param {number} chosenTherapyTypeIdentifier The chosen therapy type identifier from filter state.
 * @param {number} chosenAppointmentTypeIdentifier The chosen appointment type identifier from filter state.
 * @returns {object} The available appointments, any errors, loading state & method to reinflate the appointments (i.e., if date has changed).
 * @example const { appointments, error } = useDailyAvailableAppointments()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.3.0
 */
export const useDailyAvailableAppointments = (
	chosenTherapyTypeIdentifier: number,
	chosenAppointmentTypeIdentifier: number
): {
	appointments: InflatedAppointment[] | null
	error: FetchBaseQueryError | SerializedError | APIError | null
	isLoading: boolean
	reinflateAppointments: DispatchWithoutAction
} => {
	// Filters from Redux store
	const chosenLocationIdentifier = useChosenLocationIdentifier()
	const chosenPractitionerIdentifier = useChosenPractitionerIdentifier()
	const chosenGenderIdentifier = useChosenGenderIdentifier()
	const chosenDate = useChosenDate()

	// State to store the appointments after filtering them
	const [matchingAppointments, setMatchingAppointments] = useState<InflatedAppointment[] | null>(null)

	// We have to fetch core here as its used below to create the filter options
	const { data: core, error: coreError } = useFetchCoreQuery({})

	// Fallback to today if no date is chosen from filter state yet
	const chosenDateGuaranteed = useMemo(() => {
		if (chosenDate) return new Date(chosenDate.year, chosenDate.month, chosenDate.day)

		return new Date()
	}, [chosenDate])

	// Transform the date
	const [weekdayIdentifier, startDate] = useMemo(() => {
		const longDayName = chosenDateGuaranteed.toLocaleDateString("en-GB", { weekday: "long" }).toLowerCase()
		const startDate = `${chosenDateGuaranteed.getFullYear().toString()}-${(chosenDateGuaranteed.getMonth() + 1).toString().padStart(2, "0")}-${chosenDateGuaranteed.getDate().toString().padStart(2, "0")}`

		return [
			core?.appointment_options.weekdays.find(({ name }) => name?.toLowerCase() === longDayName)?.id,
			startDate
		]
	}, [core, chosenDateGuaranteed])

	// Fetch available filters & possible appointments using the chosen filters
	const {
		currentData: availableFilters,
		error: availableFiltersError,
		isLoading: isAvailableFiltersLoading,
		isFetching: isAvailableFiltersFetching,
		isUninitialized: isAvailableFiltersUninitialized
	} = useFetchAppointmentFiltersQuery(
		{
			therapyId: chosenTherapyTypeIdentifier, // Required
			typeId: chosenAppointmentTypeIdentifier, // Required

			locationId: chosenLocationIdentifier ?? undefined,
			practitionerId: chosenPractitionerIdentifier ?? undefined,
			genderId: chosenGenderIdentifier ?? undefined,

			weekdayId: weekdayIdentifier
		},
		{
			skip: !core && weekdayIdentifier === undefined // Avoid sending a request if we don't have the required data yet - https://redux-toolkit.js.org/rtk-query/usage/conditional-fetching
		}
	)
	const {
		currentData: appointments,
		error: appointmentsError,
		isLoading,
		isFetching,
		isUninitialized
	} = useFetchAppointmentsQuery(
		{
			//therapyId: chosenTherapyTypeIdentifier, // Required, but not allowed by API??
			typeId: chosenAppointmentTypeIdentifier, // Required

			locationId: chosenLocationIdentifier ?? undefined,
			practitionerId: chosenPractitionerIdentifier ?? undefined,
			genderId: chosenGenderIdentifier ?? undefined,

			startAt: startDate,
			weekdayId: weekdayIdentifier
		},
		{
			refetchOnMountOrArgChange: true, // Always refetch whenever the filters change
			skip: !core && weekdayIdentifier === undefined // Avoid sending a request if we don't have the required data yet
		}
	)

	// For triggering the inflation process again
	const [counter, reinflateAppointments] = useReducer<Reducer<number, void>>(value => value + 1, 0)

	// Runs after appointments are fetched or the filter changes so we can discard any irrelevant ones
	useEffect(() => {
		// Do not continue if we don't have the required data
		if (core === undefined || availableFilters === undefined || appointments === undefined) return
		if (!Array.isArray(appointments)) return // It's an API error :(

		// Don't bother sorting if there are no appointments
		if (appointments.length <= 0) {
			setMatchingAppointments([])
			return
		}

		// Inflate the appointments
		// NOTE: This will remove any irrelevant appointments!
		const inflatedAppointments = appointments
			.map(appointment =>
				InflatedAppointment.inflateOrNull({
					appointment,
					core,
					availableFilters,
					chosenTherapyTypeIdentifier,
					chosenAppointmentTypeIdentifier,
					chosenLocationIdentifier: chosenLocationIdentifier ?? undefined,
					chosenPractitionerIdentifier: chosenPractitionerIdentifier ?? undefined,
					chosenGenderIdentifier: chosenGenderIdentifier ?? undefined,
					chosenDate: chosenDateGuaranteed
				})
			)
			.filter(appointment => appointment !== null)

		// Update state with grouped appointments by date
		setMatchingAppointments(InflatedAppointment.group(inflatedAppointments))
	}, [
		setMatchingAppointments,
		appointments,
		core,
		availableFilters,
		chosenTherapyTypeIdentifier,
		chosenAppointmentTypeIdentifier,
		chosenLocationIdentifier,
		chosenPractitionerIdentifier,
		chosenGenderIdentifier,
		chosenDateGuaranteed,
		counter // Manual triggers
	])

	// Return fetch errors
	if (coreError)
		return {
			appointments: null,
			error: coreError,
			isLoading: false,
			reinflateAppointments
		}
	if (availableFiltersError)
		return {
			appointments: null,
			error: availableFiltersError,
			isLoading: false,
			reinflateAppointments
		}
	if (appointmentsError)
		return {
			appointments: null,
			error: appointmentsError,
			isLoading: false,
			reinflateAppointments
		}

	// Return API errors
	/*
	if (core !== undefined && isReduxDataAPIError(core))
		return {
			appointments: null,
			error: core,
			isLoading: false,
			reinflateAppointments
		}
	if (availableFilters !== undefined && isReduxDataAPIError(availableFilters))
		return {
			appointments: null,
			error: availableFilters,
			isLoading: false,
			reinflateAppointments
		}
	*/
	if (appointments !== undefined && isReduxDataAPIError(appointments))
		return {
			appointments: null,
			error: appointments,
			isLoading: false,
			reinflateAppointments
		}

	// Wait for core data & available filters/appointments before rendering the list
	if (
		!core ||
		!availableFilters ||
		!appointments ||
		matchingAppointments === null ||
		isLoading ||
		isFetching ||
		isUninitialized ||
		isAvailableFiltersLoading ||
		isAvailableFiltersFetching ||
		isAvailableFiltersUninitialized
	)
		return {
			appointments: null,
			error: null,
			isLoading: true,
			reinflateAppointments
		}

	// No filters? No appointments!
	if (Array.from(Object.values(availableFilters) as number[][]).every(filter => filter.length <= 0)) {
		console.warn("No available appointment filters!")
		return {
			appointments: [],
			error: null,
			isLoading: false,
			reinflateAppointments
		}
	}

	return {
		appointments: matchingAppointments,
		error: null,
		isLoading: false,
		reinflateAppointments
	}
}

import { useCallback, useEffect } from "react"

import type { AppointmentFilters } from "~/api/osteo-physio/types/endpoints/appointments/filters"
import type { Core } from "~/api/osteo-physio/types/endpoints/data/core"
import { isAPIError, isHTTPError, isReduxDataAPIError } from "~/api/osteo-physio/types/error"
import type { DatePickerProps } from "~/components/controls/datePicker"
import DatePicker from "~/components/controls/datePicker"
import ErrorMessage from "~/components/errorMessage"
import LoadingSpinner, { loadingSpinnerIconSize } from "~/components/loadingSpinner"
import InitialiseDatePickerContext from "~/contexts/datePicker"
import { isDayAvailable } from "~/helpers/availability"
import { useAppointmentTypes } from "~/hooks/appointments/useAppointmentTypes"
import { useMonthlyAvailableAppointments } from "~/hooks/appointments/useMonthlyAvailable"
import { useTherapyTypes } from "~/hooks/appointments/useTherapyTypes"
import { useAppointmentFilterDispatch } from "~/hooks/useAppointmentFilters"
import { forceAppointmentListLoading } from "~/state/slices/userInterface"
import { useReduxDispatch } from "~/state/store"

type FilteredDatePickerProps = DatePickerProps & {
	core: Core
	availableFilters: AppointmentFilters
}

/**
 * Wrapper around the date picker for filtering available appointments.
 * Core data & available appointment filters are not fetched internally to avoid duplicated requests when mounted within the filter form.
 * @example <FilteredDatePicker id="myDatePicker" core={...} availableFilters={...} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.4.3
 */
const FilteredDatePicker = ({
	id,

	core,
	availableFilters,

	...props
}: FilteredDatePickerProps): JSX.Element => {
	const { chosenTherapyTypeIdentifier } = useTherapyTypes()
	const { chosenAppointmentTypeIdentifier } = useAppointmentTypes()

	if (chosenTherapyTypeIdentifier === null || chosenAppointmentTypeIdentifier === null)
		return <LoadingSpinner size={loadingSpinnerIconSize} />

	return (
		<InitialiseDatePickerContext futureYearCount={1} futureMonthCount={12}>
			<InnerDatePicker
				{...props}
				id={id}
				core={core}
				availableFilters={availableFilters}
				chosenTherapyTypeIdentifier={chosenTherapyTypeIdentifier}
				chosenAppointmentTypeIdentifier={chosenAppointmentTypeIdentifier}
			/>
		</InitialiseDatePickerContext>
	)
}

const InnerDatePicker = ({
	id,

	core,
	availableFilters,

	chosenTherapyTypeIdentifier,
	chosenAppointmentTypeIdentifier,

	...props
}: FilteredDatePickerProps & {
	chosenTherapyTypeIdentifier: number
	chosenAppointmentTypeIdentifier: number
}): JSX.Element => {
	const dispatch = useReduxDispatch()

	// Fetch the available appointments for this month
	const { appointments, error, isLoading, forDate, setMonth, setYear } = useMonthlyAvailableAppointments(
		chosenTherapyTypeIdentifier,
		chosenAppointmentTypeIdentifier
	)

	// Filter state
	const { setChosenDate } = useAppointmentFilterDispatch()

	// Runs to check if a day is available...
	const isDateAvailable = useCallback(
		(date: Date) => {
			if (appointments === null) return false

			// If this day is unavailable due to the weekday filters...
			const isAvailableByWeekday = isDayAvailable(date, core, availableFilters)
			if (!isAvailableByWeekday) return false

			// Assume the day is available if it isn't for our month
			if (forDate?.month !== date.getMonth()) return true

			// If this day is unavailable due to no appointments...
			const appointmentsToday = appointments.filter(
				({ startAt }) =>
					startAt.getFullYear() === date.getFullYear() &&
					startAt.getMonth() === date.getMonth() &&
					startAt.getDate() === date.getDate()
			)
			if (appointmentsToday.length <= 0)
				console.warn(
					`There are no appointments on day ${date.getDate().toString()} of month ${(date.getMonth() + 1).toString()}!`
				)
			if (appointmentsToday.length > 0) return true

			// Assume the day is unavailable
			return false
		},
		[core, availableFilters, appointments, forDate?.month]
	)

	// Show loading indicator on appointment list while the date picker is loading
	useEffect(() => {
		dispatch(forceAppointmentListLoading(isLoading))
	}, [dispatch, isLoading])

	// Show fetch errors
	if (error) {
		if (isReduxDataAPIError(error))
			return <ErrorMessage title="API Error" content={error.SystemErrorMessage?.toString()} />

		if (isAPIError(error))
			return <ErrorMessage title="API Error" content={error.data.SystemErrorMessage?.toString()} />

		if (isHTTPError(error)) return <ErrorMessage title="HTTP Error" content={error.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to fetch available appointments!" />
	}

	// We can't do much without any filters...
	if (Array.from(Object.values(availableFilters) as number[][]).every(filter => filter.length <= 0))
		return <ErrorMessage title="API Error" content="No available appointment filters!" />

	return (
		<DatePicker
			{...props}
			id={id}
			isLoading={appointments === null || isLoading}
			isDateAvailable={isDateAvailable}
			knownMonth={forDate?.month}
			onChoose={setChosenDate}
			onMonthChange={setMonth}
			onYearChange={setYear}
		/>
	)
}

export default FilteredDatePicker

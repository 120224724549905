import { BuildingOffice2Icon, EnvelopeIcon, HomeIcon, MapIcon } from "@heroicons/react/24/solid"
import { useCallback } from "react"
import { useFetchAssociationsQuery } from "~/api/osteo-physio/client"

import Form from "~/components/controls/forms/form"
import TextInput from "~/components/controls/inputs/text"
import BackOrContinueButtons from "~/components/onboarding/backOrContinueButtons"
import Paragraphs from "~/components/onboarding/paragraphs"
import StageWrapper from "~/components/onboarding/wrapper"
import Paragraph from "~/components/standard/text/paragraph"
import { longerColorTransitionStyles } from "~/config/transitions"
import { inputDisabledColorStyles, inputIconSize } from "~/constants/components/input"
import { useFlowContext } from "~/contexts/flow"
import { useFormDispatch } from "~/hooks/useForm"
import { useOnboardingDispatch, useOnboardingSelector } from "~/hooks/useOnboarding"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import { OnboardingStage } from "~/types/components/pages/onboarding"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	ManualAddress = "manualAddress",
	FirstLine = "firstLine",
	SecondLine = "secondLine",
	ThirdLine = "thirdLine",
	City = "city",
	County = "county",
	PostCode = "postCode"
}

/**
 * A sub-stage of the address stage for manually entering a full address.
 * This should be wrapped in a <StageFlow /> component!
 * @example <ManualAddressStage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
const ManualAddressStage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	// Form
	const { showWarning } = useFormDispatch(HTMLElementIdentifiers.ManualAddress)

	// Onboarding
	const { transfer } = useFlowContext()
	const { setAssociation, setManualAddress, clearManualAddress } = useOnboardingDispatch()
	const { manualAddress } = useOnboardingSelector()

	// API
	const { data: associations } = useFetchAssociationsQuery({})

	const onSubmitted = useCallback<OnFormSubmitCallback>(
		(values): void => {
			const firstLine = values.get(HTMLElementIdentifiers.FirstLine) as string | null
			const secondLine = values.get(HTMLElementIdentifiers.SecondLine) as string | null
			const county = values.get(HTMLElementIdentifiers.County) as string | null
			const city = values.get(HTMLElementIdentifiers.City) as string | null
			const postCode = values.get(HTMLElementIdentifiers.PostCode) as string | null

			// Shouldn't happen, but check just in case to please TypeScript
			if (firstLine === null || firstLine === "") {
				console.warn("No first line was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.FirstLine, "Enter your first address line!")
				clearManualAddress()
				return
			}

			// Shouldn't happen, but check just in case to please TypeScript
			if (city === null || city === "") {
				console.warn("No city was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.City, "Enter your city!")
				clearManualAddress()
				return
			}

			// Shouldn't happen, but check just in case to please TypeScript
			if (county === null || county === "") {
				console.warn("No county was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.PostCode, "Enter your county!")
				clearManualAddress()
				return
			}

			// Shouldn't happen, but check just in case to please TypeScript
			if (postCode === null || postCode === "") {
				console.warn("No post code was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.PostCode, "Enter your post code name!")
				clearManualAddress()
				return
			}

			console.info(`Entered address '${firstLine}, ${city}, ${county}, ${postCode}'`)
			setManualAddress({
				houseNumber: null, // We don't really need this on manual entry
				firstLine,
				secondLine,
				city,
				county,
				postCode
			})

			if (associations?.length === 1) {
				const association = associations[0]
				if (association === undefined) throw new Error("No association at 1st index?!")
				setAssociation({
					id: association.id,
					name: association.name ?? "Unknown Association"
				})

				console.info(
					`There is only one association (${association.id.toString()}, '${association.name ?? "N/A"}'), skipping to agreement stage...`
				)

				transfer(OnboardingStage.Agreement)
			} else {
				transfer(OnboardingStage.Association)
			}
		},
		[showWarning, clearManualAddress, setManualAddress, transfer, setAssociation, associations]
	)

	return (
		<StageWrapper {...props} className="justify-end">
			<Paragraphs>
				<Paragraph>Please enter your address below to continue creating your account.</Paragraph>
			</Paragraphs>

			<Form id={HTMLElementIdentifiers.ManualAddress} onSubmit={onSubmitted}>
				<TextInput
					id={HTMLElementIdentifiers.FirstLine}
					label="First Line"
					placeholder="5 Fore Street..."
					initialValue={manualAddress?.firstLine ?? undefined}
					tooltip="Please enter the first line of your address."
					missingValueWarningMessage="Enter the first line of your address!"
					startIcon={isLoading => (
						<HomeIcon
							className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)}
				/>

				<TextInput
					id={HTMLElementIdentifiers.SecondLine}
					label="Second Line"
					initialValue={manualAddress?.secondLine ?? undefined}
					tooltip="Please enter the second line of your address."
					missingValueWarningMessage="Enter the second line of your address!"
					isRequired={false}
					startIcon={isLoading => (
						<HomeIcon
							className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)}
				/>
				<TextInput
					id={HTMLElementIdentifiers.City}
					label="City"
					placeholder="Cullompton..."
					initialValue={manualAddress?.city ?? undefined}
					tooltip="Please enter your city."
					missingValueWarningMessage="Enter the city of your address!"
					startIcon={isLoading => (
						<BuildingOffice2Icon
							className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)}
				/>

				<TextInput
					id={HTMLElementIdentifiers.County}
					label="County"
					placeholder="Devon..."
					initialValue={manualAddress?.county ?? undefined}
					tooltip="Please enter your county."
					missingValueWarningMessage="Enter the county of your address!"
					startIcon={isLoading => (
						<MapIcon
							className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)}
				/>

				<TextInput
					id={HTMLElementIdentifiers.PostCode}
					label="Post Code"
					placeholder="EX15 1JW..."
					initialValue={manualAddress?.postCode ?? undefined}
					tooltip="Please enter your post code."
					missingValueWarningMessage="Enter the post code of your address!"
					startIcon={isLoading => (
						<EnvelopeIcon
							className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)}
				/>

				<BackOrContinueButtons />
			</Form>
		</StageWrapper>
	)
}

export default ManualAddressStage

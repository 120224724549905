import {
	BuildingOffice2Icon,
	CalendarDaysIcon,
	EnvelopeIcon,
	HomeIcon,
	MapIcon,
	MapPinIcon,
	UserGroupIcon,
	UserIcon
} from "@heroicons/react/24/solid"
import { useCallback, useEffect, useMemo } from "react"

import {
	useFetchAssociationsQuery,
	useFetchCoreQuery,
	useLazySwitchUserQuery,
	useLazyUpdateUserQuery
} from "~/api/osteo-physio/client"
import { ErrorMessages, isAPIError, isHTTPError, isStandardError } from "~/api/osteo-physio/types/error"
import Form from "~/components/controls/forms/form"
import SubmitButton from "~/components/controls/forms/submitButton"
import DateInput from "~/components/controls/inputs/date"
import DropDownInput from "~/components/controls/inputs/dropDown"
import EmailAddressInput from "~/components/controls/inputs/emailAddress"
import PhoneNumberInput from "~/components/controls/inputs/phoneNumber"
import SwitchInput from "~/components/controls/inputs/switch"
import TextInput from "~/components/controls/inputs/text"
import ErrorMessage from "~/components/errorMessage"
import LoadingSpinner, { loadingSpinnerIconSize } from "~/components/loadingSpinner"
import Page from "~/components/standard/layout/page"
import Section from "~/components/standard/layout/section"
import Heading from "~/components/standard/text/heading"
import { longerColorTransitionStyles } from "~/config/transitions"
import { dropDownIconStyles } from "~/constants/components/dropDown"
import { inputDisabledColorStyles, inputIconSize } from "~/constants/components/input"
import { toNumber } from "~/helpers/convert"
import { parseDatePickerValue } from "~/helpers/inputs/dateOfBirth"
import { orNull } from "~/helpers/null"
import { prettyPhoneNumber } from "~/helpers/phoneNumber"
import { tidyUpAlternativeUser } from "~/helpers/tidy-ups/user/alternative"
import { tidyUpUser } from "~/helpers/tidy-ups/user/user"
import { useAuthSessionDispatch, useAuthSessionSelector } from "~/hooks/useAuthSession"
import { useFormDispatch } from "~/hooks/useForm"
import { useModalDispatch } from "~/hooks/useModal"
import { isPartialUser } from "~/state/slices/authSession"
import type { OnItemChooseCallback } from "~/types/components/controls/dropDown"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	SwitchUser = "switchUser",

	UpdateUser = "updateUser",

	FirstName = "firstName",
	LastName = "lastName",

	EmailAddress = "emailAddress",
	MobileNumber = "mobileNumber",
	HomeNumber = "homeNumber",

	DateOfBirth = "dateOfBirth",

	FirstLine = "line1",
	SecondLine = "line2",
	ThirdLine = "line3",
	City = "city",
	County = "county",
	PostCode = "postCode",

	Association = "association",

	//Contact = "contact",
	Reminders = "reminders"
	//Surveys = "surveys"
}

/**
 * The account page.
 * This page handles viewing and updating account details.
 * @example <AccountPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const AccountPage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	// Form
	const { showWarning, clearWarnings, updateLoading } = useFormDispatch(HTMLElementIdentifiers.UpdateUser)

	// Modal
	const { showNoticeModal } = useModalDispatch()

	// Auth
	const { user } = useAuthSessionSelector()
	const { updateSessionUser } = useAuthSessionDispatch()

	// API queries
	const { data: core, error: coreError, refetch } = useFetchCoreQuery({})
	const { data: associations } = useFetchAssociationsQuery({}) // Will absolutely definitely never ever error, or so we're told
	const [updateUser, updateUserResponse] = useLazyUpdateUserQuery()
	const [switchUser, switchUserResponse] = useLazySwitchUserQuery()

	// Tidy up alternative users
	const alternativeUsers = useMemo(() => core?.alternative_users.map(tidyUpAlternativeUser) ?? [], [core])

	// Runs when the save button is pressed...
	const onSubmit = useCallback<OnFormSubmitCallback>(
		values => {
			if (!associations) return // Do not continue if we don't have the required data
			if (!user || isPartialUser(user)) return // Do not continue if the user is not fully signed in

			const firstName = values.get(HTMLElementIdentifiers.FirstName) as string | null
			const lastName = values.get(HTMLElementIdentifiers.LastName) as string | null
			const dateOfBirthText = values.get(HTMLElementIdentifiers.DateOfBirth) as string | null
			const emailAddress = values.get(HTMLElementIdentifiers.EmailAddress) as string | null
			const mobileNumber = values.get(HTMLElementIdentifiers.MobileNumber) as string | null
			const homeNumber = values.get(HTMLElementIdentifiers.HomeNumber) as string | null // Optional
			const firstLine = values.get(HTMLElementIdentifiers.FirstLine) as string | null
			const secondLine = values.get(HTMLElementIdentifiers.SecondLine) as string | null // Optional
			const thirdLine = values.get(HTMLElementIdentifiers.ThirdLine) as string | null // Optional
			const city = values.get(HTMLElementIdentifiers.City) as string | null
			const county = values.get(HTMLElementIdentifiers.County) as string | null
			const postCode = values.get(HTMLElementIdentifiers.PostCode) as string | null
			const associationId = values.get(HTMLElementIdentifiers.Association) as number | null
			//const confirmContact = values.get(HTMLElementIdentifiers.Contact) as boolean | undefined ?? false
			const confirmReminders = (values.get(HTMLElementIdentifiers.Reminders) as boolean | undefined) ?? false
			//const confirmSurveys = values.get(HTMLElementIdentifiers.Surveys) as boolean | undefined ?? false

			// Shouldn't happen, but check just in case to please TypeScript
			if (firstName === null) {
				console.warn("No first name was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.FirstName, "Enter your first name!")
				return
			}
			if (lastName === null) {
				console.warn("No last name was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.LastName, "Enter your last name!")
				return
			}
			if (dateOfBirthText === null) {
				console.warn("No date of birth was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.DateOfBirth, "Enter your date of birth!")
				return
			}
			if (emailAddress === null) {
				console.warn("No email address was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.EmailAddress, "Enter your email address!")
				return
			}
			if (mobileNumber === null) {
				console.warn("No first name was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.MobileNumber, "Enter your mobile number!")
				return
			}
			if (firstLine === null) {
				console.warn("No first address line was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.FirstLine, "Enter the first line of your address!")
				return
			}
			if (city === null) {
				console.warn("No city was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.City, "Enter your city!")
				return
			}
			if (county === null) {
				console.warn("No county was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.County, "Enter your county!")
				return
			}
			if (postCode === null) {
				console.warn("No post code was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.PostCode, "Enter your post code!")
				return
			}
			if (associationId === null) {
				console.warn("No association was selected, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.Association, "Select an association!")
				return
			}

			// Ensure the association ID is valid
			if (!associations.some(association => association.id === associationId)) {
				console.warn("The chosen association was not found in the list of associations?!")
				showWarning(HTMLElementIdentifiers.Association, "Unknown association! Please choose another.")
				return
			}

			// Parse the date of birth
			const dateOfBirth = parseDatePickerValue(dateOfBirthText)
			if (!dateOfBirth) {
				console.warn("The date of birth was entered in an invalid format?!")
				showWarning(
					HTMLElementIdentifiers.DateOfBirth,
					"Enter your date of birth in the DD / MM / YYYY format!"
				)
				return
			}

			// Ensure the date of birth is within a reasonable range (e.g., 1924 - 2024)
			const today = new Date()
			const oneHundredYearsAgo = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate())
			if (dateOfBirth > today || dateOfBirth < oneHundredYearsAgo) {
				console.warn("The date of birth is out of range?!")
				showWarning(HTMLElementIdentifiers.DateOfBirth, "Enter a date of birth within a suitable range!")
				return
			}

			// Convert the date of birth to a format the API will accept
			const dateOfBirthYYYYMMDD = dateOfBirth.toISOString().split("T")[0]
			if (dateOfBirthYYYYMMDD === undefined) {
				console.warn("Invalid ISO 8601 date for date of birth?!")
				showWarning(HTMLElementIdentifiers.DateOfBirth, "Enter a date of birth in the DD / MM / YYYY format!")
				return
			}

			/* eslint-disable promise/prefer-await-to-then */
			updateLoading(true)
			updateUser({
				id: user.user_id, // Not part of the form

				first_name: firstName,
				last_name: lastName,

				date_of_birth: dateOfBirthYYYYMMDD,

				email: emailAddress,
				mobile_phone: mobileNumber,
				home_phone: homeNumber, // Optional

				address_line_1: firstLine,
				address_line_2: secondLine, // Optional
				address_line_3: thirdLine, // Optional
				city: city,
				state: county,
				post_code: postCode,

				association_id: associationId,

				//agree_contact: confirmContact,
				agree_reminders: confirmReminders
				//agree_survey: confirmSurveys
			}).catch((error: unknown) => {
				console.warn(
					`Failed to update user '${user.user_id.toString()}'! (${error?.toString() ?? "Unknown error"})`
				)
				showWarning(HTMLElementIdentifiers.FirstName, "Failed to update user!")
				return
			})
		},
		[showWarning, updateLoading, updateUser, user, associations]
	)

	// Runs when an alternative user is selected...
	const onSwitchUser = useCallback<OnItemChooseCallback>(
		(value: string) => {
			const userId = toNumber(value) ?? null
			if (userId === null) {
				console.warn("Invalid user identifier?!")
				showWarning(HTMLElementIdentifiers.SwitchUser, "Select a user from the list!")
				return
			}

			/* eslint-disable promise/prefer-await-to-then */
			updateLoading(true)
			clearWarnings()
			switchUser({
				id: userId
			}).catch((error: unknown) => {
				console.warn(`Failed to switch user! (${error?.toString() ?? "Unknown error"})`)
				showWarning(
					HTMLElementIdentifiers.SwitchUser,
					"Sorry, an unknown problem occurred. Please try again later."
				)
			})
		},
		[showWarning, clearWarnings, updateLoading, switchUser]
	)

	// Runs after the user is switched...
	useEffect(() => {
		if (switchUserResponse.isUninitialized || switchUserResponse.isFetching || switchUserResponse.isLoading) return

		updateLoading(false)

		if (switchUserResponse.isError) {
			// API error message
			if (isAPIError(switchUserResponse.error)) {
				if (switchUserResponse.error.data.SystemErrorMessage === ErrorMessages.Success.valueOf()) return // API can return success within an error message?!

				console.warn(
					`Failed to switch user! (API said '${switchUserResponse.error.data.SystemErrorMessage?.toString() ?? "Unknown error"}')`
				)
				showWarning(HTMLElementIdentifiers.SwitchUser, "API error! See console for more information.")
				return
			}

			// HTTP error code
			if (isHTTPError(switchUserResponse.error)) {
				console.warn(`Failed to switch user! (HTTP ${switchUserResponse.error.status.toString()})`)
				showWarning(
					HTMLElementIdentifiers.SwitchUser,
					`HTTP ${switchUserResponse.error.status.toString()}! See console for more information.`
				)
				return
			}

			// Fetch aborted (e.g., timeout?)
			if (isStandardError(switchUserResponse.error) && switchUserResponse.error.name === "AbortError") {
				console.warn("Failed to switch user! (Request was aborted)")
				showWarning(HTMLElementIdentifiers.SwitchUser, "The request was aborted! Please try again.")
				return
			}

			// Unknown
			console.error(`Failed to switch user! (${JSON.stringify(switchUserResponse.error)})`)
			showWarning(HTMLElementIdentifiers.SwitchUser, "An unknown error occurred! Please try again later.")
			return
		}

		// Refetch core to update user in global state
		/* eslint-disable promise/prefer-await-to-then */
		console.info("User switched!")
		updateLoading(true)
		refetch()
			.then(result => {
				if (!result.data) {
					console.warn("Cannot update session user without core data!")
					return
				}

				const newUser = tidyUpUser(result.data.patient_details)
				updateSessionUser(newUser)
				console.info("Session user updated!")

				return result
			})
			.catch((error: unknown) => {
				console.warn(`Failed to refetch core data (${error?.toString() ?? "Unknown error"})`)
				showWarning(HTMLElementIdentifiers.Association, "Failed to fetch app data! Please try again later.")
			})

			.finally(() => {
				updateLoading(false)
			})
	}, [showWarning, updateLoading, refetch, updateSessionUser, switchUserResponse])

	// Runs after the user is updated...
	useEffect(() => {
		if (updateUserResponse.isUninitialized || updateUserResponse.isFetching || updateUserResponse.isLoading) return

		updateLoading(false)

		if (updateUserResponse.isError) {
			// API error message
			if (isAPIError(updateUserResponse.error)) {
				if (updateUserResponse.error.data.SystemErrorMessage === ErrorMessages.Success.valueOf()) return // API can return success within an error message?!

				console.warn(
					`Failed to update user! (API said '${updateUserResponse.error.data.SystemErrorMessage?.toString() ?? "Unknown error"}')`
				)
				showWarning(HTMLElementIdentifiers.Association, "API error! See console for more information.")
				return
			}

			// HTTP error code
			if (isHTTPError(updateUserResponse.error)) {
				console.warn(`Failed to update user! (HTTP ${updateUserResponse.error.status.toString()})`)
				showWarning(
					HTMLElementIdentifiers.Association,
					`HTTP ${updateUserResponse.error.status.toString()}! See console for more information.`
				)
				return
			}

			// Fetch aborted (e.g., timeout?)
			if (isStandardError(updateUserResponse.error) && updateUserResponse.error.name === "AbortError") {
				console.warn("Failed to update user! (Request was aborted)")
				showWarning(HTMLElementIdentifiers.Association, "The request was aborted! Please try again.")
				return
			}

			// Unknown
			console.error(`Failed to update user! (${JSON.stringify(updateUserResponse.error)})`)
			showWarning(HTMLElementIdentifiers.Association, "An unknown error occurred! Please try again later.")
			return
		}

		// Refetch core to update user in global state
		/* eslint-disable promise/prefer-await-to-then */
		console.info("User updated!")
		updateLoading(true)
		refetch()
			.then(result => {
				if (!result.data) {
					console.warn("Cannot update session user without core data!")
					return
				}

				const newUser = tidyUpUser(result.data.patient_details)
				updateSessionUser(newUser)
				console.info("Session user updated!")

				showNoticeModal("Account Updated", "Your account details have been updated!")

				return result
			})
			.catch((error: unknown) => {
				console.warn(`Failed to refetch core data (${error?.toString() ?? "Unknown error"})`)
				showWarning(HTMLElementIdentifiers.Association, "Failed to fetch app data! Please try again later.")
			})
			.finally(() => {
				updateLoading(false)
			})
	}, [showWarning, updateLoading, refetch, updateSessionUser, showNoticeModal, updateUserResponse])

	// Loading while we wait for the associations to be fetched
	useEffect(() => {
		updateLoading(!associations)
	}, [updateLoading, associations])

	// Handle fetch errors
	if (coreError) {
		if (isAPIError(coreError))
			return <ErrorMessage title="API Error" content={coreError.data.SystemErrorMessage?.toString()} />

		if (isHTTPError(coreError)) return <ErrorMessage title="HTTP Error" content={coreError.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to fetch core data!" />
	}

	// Wait for core app data
	if (!core) return <LoadingSpinner size={loadingSpinnerIconSize} />

	// Do not continue if the user is not fully signed in
	if (!user || isPartialUser(user)) return <ErrorMessage title="User" content="User is not signed in!" />

	// Show loading in drop-down until we have the associations
	const associationDropDownEntries: Record<string, string> = Object.fromEntries(
		(associations
			?.map(association => [association.id.toString(), orNull(association.name)?.trim() ?? null])
			.filter(([, name]) => name !== null) as [string, string][] | undefined) ?? [
			["0", "Loading list of associations..."]
		]
	)

	return (
		<Page {...props}>
			<Section title="My Account">
				<Form id={HTMLElementIdentifiers.UpdateUser} onSubmit={onSubmit}>
					{alternativeUsers.length > 0 && (
						<>
							<Heading level={2} className="text-base font-bold text-formLabel">
								Alternative Users
							</Heading>

							<DropDownInput
								id={HTMLElementIdentifiers.SwitchUser}
								wide={true}
								label="User"
								choices={Object.fromEntries([
									// Other users
									...alternativeUsers.map(user => [
										user.patient_id.toString(),
										`${user.first_name} ${user.last_name}`
									]),

									// Current user
									[user.user_id.toString(), `${user.first_name} ${user.last_name}`]
								] as [string, string][])}
								initialValue={user.user_id.toString()}
								onChoose={onSwitchUser}
								startIcon={isLoading => (
									<UserGroupIcon
										className={`${longerColorTransitionStyles} ${dropDownIconStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
										width={inputIconSize}
										height={inputIconSize}
									/>
								)}
							/>
						</>
					)}

					<Heading
						level={2}
						className={`text-base font-bold text-formLabel ${alternativeUsers.length > 0 ? "mt-3" : ""}`.trimEnd()}>
						About You
					</Heading>

					<TextInput
						id={HTMLElementIdentifiers.FirstName}
						label="First Name"
						placeholder="John..."
						missingValueWarningMessage="Enter your first name!"
						initialValue={user.first_name}
						startIcon={isLoading => (
							<UserIcon
								className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
								width={inputIconSize}
								height={inputIconSize}
							/>
						)}
					/>

					<TextInput
						id={HTMLElementIdentifiers.LastName}
						label="Last Name"
						placeholder="Doe..."
						missingValueWarningMessage="Enter your last name!"
						initialValue={user.last_name}
						startIcon={isLoading => (
							<UserIcon
								className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
								width={inputIconSize}
								height={inputIconSize}
							/>
						)}
					/>

					<DateInput
						key={user.user_id}
						id={HTMLElementIdentifiers.DateOfBirth}
						label="Date of Birth"
						missingValueWarningMessage="Enter your date of birth!"
						initialValue={user.date_of_birth !== null ? new Date(user.date_of_birth) : undefined}
						pickerLeftOffset={60}
						startIcon={isLoading => (
							<CalendarDaysIcon
								className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
								width={inputIconSize}
								height={inputIconSize}
							/>
						)}
					/>

					<Heading level={2} className="mt-3 text-base font-bold text-formLabel">
						Contact Methods
					</Heading>

					<EmailAddressInput
						id={HTMLElementIdentifiers.EmailAddress}
						initialValue={user.email ?? undefined}
					/>

					<PhoneNumberInput
						id={HTMLElementIdentifiers.MobileNumber}
						label="Mobile Number"
						initialValue={prettyPhoneNumber(user.mobile_phone)}
					/>
					<PhoneNumberInput
						id={HTMLElementIdentifiers.HomeNumber}
						label="Home Number"
						placeholder="01234 567890..."
						isRequired={false}
						initialValue={user.home_phone !== null ? prettyPhoneNumber(user.home_phone) : undefined}
					/>

					<Heading level={2} className="mt-3 text-base font-bold text-formLabel">
						Address
					</Heading>

					<TextInput
						id={HTMLElementIdentifiers.FirstLine}
						label="First Line"
						placeholder="5 Fore Street..."
						tooltip="Please enter the first line of your address."
						missingValueWarningMessage="Enter the first line of your address!"
						initialValue={user.address_line_1 ?? undefined}
						startIcon={isLoading => (
							<HomeIcon
								className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
								width={inputIconSize}
								height={inputIconSize}
							/>
						)}
					/>
					<TextInput
						id={HTMLElementIdentifiers.SecondLine}
						label="Second Line"
						tooltip="Please enter the second line of your address."
						missingValueWarningMessage="Enter the second line of your address!"
						initialValue={user.address_line_2 ?? undefined}
						isRequired={false}
						startIcon={isLoading => (
							<HomeIcon
								className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
								width={inputIconSize}
								height={inputIconSize}
							/>
						)}
					/>
					<TextInput
						id={HTMLElementIdentifiers.ThirdLine}
						label="Third Line"
						tooltip="Please enter the third line of your address."
						missingValueWarningMessage="Enter the third line of your address!"
						initialValue={user.address_line_3 ?? undefined}
						isRequired={false}
						startIcon={isLoading => (
							<HomeIcon
								className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
								width={inputIconSize}
								height={inputIconSize}
							/>
						)}
					/>
					<TextInput
						id={HTMLElementIdentifiers.City}
						label="City"
						placeholder="Cullompton..."
						tooltip="Please enter your city."
						missingValueWarningMessage="Enter the city!"
						initialValue={user.city ?? undefined}
						startIcon={isLoading => (
							<BuildingOffice2Icon
								className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
								width={inputIconSize}
								height={inputIconSize}
							/>
						)}
					/>
					<TextInput
						id={HTMLElementIdentifiers.County}
						label="County"
						placeholder="Devon..."
						tooltip="Please enter your county."
						missingValueWarningMessage="Enter your county!"
						initialValue={user.state ?? undefined}
						startIcon={isLoading => (
							<MapIcon
								className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
								width={inputIconSize}
								height={inputIconSize}
							/>
						)}
					/>
					<TextInput
						id={HTMLElementIdentifiers.PostCode}
						label="Post Code"
						placeholder="EX15 1JW..."
						tooltip="Please enter your post code."
						missingValueWarningMessage="Enter your post code!"
						initialValue={user.post_code ?? undefined}
						startIcon={isLoading => (
							<EnvelopeIcon
								className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
								width={inputIconSize}
								height={inputIconSize}
							/>
						)}
					/>

					<DropDownInput
						key={Object.keys(associationDropDownEntries).join("-")}
						id={HTMLElementIdentifiers.Association}
						label="Association"
						choices={associationDropDownEntries}
						initialValue={user.association_id.toString()}
						startIcon={isLoading => (
							<MapPinIcon
								className={`${longerColorTransitionStyles} ${dropDownIconStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
								width={inputIconSize}
								height={inputIconSize}
							/>
						)}
					/>

					<Heading level={2} className="mt-3 text-base font-bold text-formLabel">
						Agreements
					</Heading>

					{/* Client doesn't want users to adjust their preferences after the fact :? */}
					{/*
					<SwitchInput
						key={`${HTMLElementIdentifiers.Contact}-${user.user_id.toString()}`}
						id={HTMLElementIdentifiers.Contact}
						label="Contact"
						initialValue={user.agree_contact}
					/>
					*/}
					<SwitchInput
						key={`${HTMLElementIdentifiers.Reminders}-${user.user_id.toString()}`}
						id={HTMLElementIdentifiers.Reminders}
						label="Appointment Reminders"
						initialValue={user.agree_reminders}
					/>
					{/*
					<SwitchInput
						key={`${HTMLElementIdentifiers.Surveys}-${user.user_id.toString()}`}
						id={HTMLElementIdentifiers.Surveys}
						label="Feedback & Surveys"
						initialValue={user.agree_survey}
					/>
					*/}

					{/* Cannot use <StickyButton /> as it's a submit & positioning method is different */}
					<SubmitButton
						label="Save"
						loadingLabel={
							switchUserResponse.isFetching || switchUserResponse.isLoading ? "Switching..." : "Saving..."
						}
						className="sticky bottom-4 z-40 shadow"
					/>
				</Form>
			</Section>
		</Page>
	)
}

export default AccountPage

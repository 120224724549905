import { CalendarDaysIcon } from "@heroicons/react/24/solid"
import { useCallback } from "react"

import Form from "~/components/controls/forms/form"
import DateInput from "~/components/controls/inputs/date"
import BackOrContinueButtons from "~/components/onboarding/backOrContinueButtons"
import Paragraphs from "~/components/onboarding/paragraphs"
import StageWrapper from "~/components/onboarding/wrapper"
import Paragraph from "~/components/standard/text/paragraph"
import { longerColorTransitionStyles } from "~/config/transitions"
import { inputDisabledColorStyles, inputIconSize } from "~/constants/components/input"
import { useFlowContext } from "~/contexts/flow"
import { isDate } from "~/helpers/date"
import { parseDatePickerValue } from "~/helpers/inputs/dateOfBirth"
import { useFormDispatch } from "~/hooks/useForm"
import { useOnboardingDispatch, useOnboardingSelector } from "~/hooks/useOnboarding"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import { OnboardingStage } from "~/types/components/pages/onboarding"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	DateOfBirthForm = "registerDateOfBirth",
	DateOfBirth = "dateOfBirth"
}

/**
 * The third stage of the onboarding process.
 * This stage collects the user's date of birth for registering a new account.
 * This should be wrapped in a <StageFlow /> component!
 * @example <DateOfBirthStage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const DateOfBirthStage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const { transfer } = useFlowContext()

	const { showWarning } = useFormDispatch(HTMLElementIdentifiers.DateOfBirthForm)

	const { setDateOfBirth, clearDateOfBirth } = useOnboardingDispatch()
	const { dateOfBirth } = useOnboardingSelector()

	// Runs when the form is submitted...
	const onSubmitted = useCallback<OnFormSubmitCallback>(
		(values): void => {
			const dateOfBirthText = values.get(HTMLElementIdentifiers.DateOfBirth) as string | null

			// Shouldn't happen, but check just in case to please TypeScript
			if (dateOfBirthText === null) {
				console.warn("No date of birth was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.DateOfBirth, "Enter your date of birth!")
				clearDateOfBirth()
				return
			}

			// Parse the value
			const dateOfBirth = parseDatePickerValue(dateOfBirthText)
			if (!dateOfBirth) {
				console.warn("The date of birth was entered in an invalid format?!")
				showWarning(
					HTMLElementIdentifiers.DateOfBirth,
					"Enter your date of birth in the DD / MM / YYYY format!"
				)
				clearDateOfBirth()
				return
			}

			// Ensure it is within a reasonable range (e.g., 1924 - 2024)
			const today = new Date()
			const oneHundredYearsAgo = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate())
			if (dateOfBirth > today || dateOfBirth < oneHundredYearsAgo) {
				console.warn("The date of birth is out of range?!")
				showWarning(HTMLElementIdentifiers.DateOfBirth, "Enter a date of birth within a suitable range!")
				clearDateOfBirth()
				return
			}

			setDateOfBirth(dateOfBirth)
			transfer(OnboardingStage.EmailAddress)
		},
		[showWarning, clearDateOfBirth, setDateOfBirth, transfer]
	)

	return (
		<StageWrapper {...props}>
			<Paragraphs>
				<Paragraph>Please enter your birth date below to continue creating your account.</Paragraph>
			</Paragraphs>

			<Form id={HTMLElementIdentifiers.DateOfBirthForm} onSubmit={onSubmitted}>
				<DateInput
					id={HTMLElementIdentifiers.DateOfBirth}
					label="Date of Birth"
					initialValue={isDate(dateOfBirth) ? dateOfBirth : undefined}
					missingValueWarningMessage="Enter your date of birth!"
					isFocused={true}
					pickerLeftOffset={-130}
					startIcon={isLoading => (
						<CalendarDaysIcon
							className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)}
				/>

				<BackOrContinueButtons previousStage={OnboardingStage.Name} />
			</Form>
		</StageWrapper>
	)
}

export default DateOfBirthStage
